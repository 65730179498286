.header-top-container {
    position: relative;
    height: 90px;
}

.navbar-brand {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: auto;
    img {
        max-height: 60px;
        width: 100%;
    }
}

.navbar-default {
    background-color: $brand-primary;
    border: 0 !important;
    line-height: $nav-line-height;
    font-size: 16px;
    -webkit-box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.25);
    .navbar-header {
        position: absolute;
        left: 0;
        top: 0;
        height: 90px;
        width: 100%;
        background-color: #ffffff;
        .contact-button {
            -webkit-appearance: none;
            outline: none;
            margin: 0;
            border-radius: 0;
            border: 0;
            padding: 0 20px;
            height: 90px;
            width: 185px;
            line-height: 1.1em;
            float: right;
            color: #ffffff;
            background-color: $brand-quole-primary;
            font-size: 20px;
            font-family: $nav-font-family;
            text-transform: uppercase;
            text-align: left;
            -webkit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
            img {
                float: left;
                margin-right: 4px;
                width: 44px;
                fill: #ffffff;
            }
            &:hover, &:active {
                background-color: darken($brand-quole-primary, 10%);
            }
            @media (max-width: $screen-xs-max) {
                display: none;
            }
        }
        .header-toggle {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .2s ease-in-out;
            -moz-transition: .2s ease-in-out;
            -o-transition: .2s ease-in-out;
            transition: .2s ease-in-out;
            cursor: pointer;
            margin: 0;
            border-radius: 0;
            border: 0;
            border-right: 1px solid $secondary-border-color;
            padding: 0;
            width: 100px;
            height: 90px;
            display: inline-block;
            float: left;
            color: $hover-color;
            background-color: #ffffff;
            font-family: $nav-font-family;
            text-transform: uppercase;
            text-align: center;
            @media (max-width: $screen-xs-max) {
                width: 90px;
            }
            &:hover, &:active, &.open {
                color: #ffffff;
                background-color: $brand-primary;
            }
            .header-button-label {
                display: block;
                margin-top: 32px;
                font-size: 14px;
                line-height: $nav-line-height;
            }
        }

        .search-toggle {
            -webkit-appearance: none;
            outline: none;
            &:hover, &:active, &.open {
                i {
                    color: #ffffff;
                }
            }
            @media (max-width: $screen-xs-max) {
                float: right;
                border-right: 0;
                border-left: 1px solid $secondary-border-color;
            }
            i {
                font-size: 29px;
                color: #000000;
                margin-top: -5px;
            }
            .header-button-label {
                margin-top: 7px;

            }
        }
        .navbar-toggle {
            .icon-bar {
                display: block;
                position: absolute;
                width: 24px;
                height: 3px;
                background-color: #000000;
                border-radius: 0;
                opacity: 1;
                left: 38px;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .25s ease-in-out;
                -moz-transition: .25s ease-in-out;
                -o-transition: .25s ease-in-out;
                transition: .25s ease-in-out;

                @media (max-width: $screen-xs-max) {
                    left: 33px;
                }
            }
            &:hover, &:active, &.open {
                .icon-bar {
                    background-color: #ffffff;
                }
            }
            .icon-bar:nth-child(1) {
                top: 24px;
            }

            .icon-bar:nth-child(2), .icon-bar:nth-child(3) {
                top: 28px;
            }

            .icon-bar:nth-child(4) {
                top: 36px;
            }
            &.open {
                .icon-bar:nth-child(1) {
                    top: 28px;
                    width: 0%;
                    left: 50%;
                }
                .icon-bar:nth-child(2) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                .icon-bar:nth-child(3) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }

                .icon-bar:nth-child(4) {
                    top: 28px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
    .navbar-collapse {
        position: relative;
        margin-top: 0;
        height: auto;
        padding: 30px 100px;
        // max-height: none;
        box-shadow: none;
        max-height: calc(100vh - 90px);
        @media (max-width: $screen-sm-max) {
            padding: 30px;
        }
        ul.navbar-nav {
            bottom: 0;
            right: 0;
            list-style-type: none;
            text-align: left;
            float: left;
            padding: 0;
            margin: 0 !important;
            &:before {
                display: none;
            }
            li {
                a {
                    display: inline-block;
                    font-family: $nav-font-family;
                    font-weight: $nav-font-weight;
                    color: #ffffff;
                    background-color: transparent;
                    padding: 12px 0;
                    font-size: 16px;
                    line-height: 1em;
                    width: 100%;
                    -webkit-transition: all 0.2s ease;
                    -moz-transition: all 0.2s ease;
                    -o-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                    &.dropdown-toggle {
                        font-family: $headings-font-family;
                        font-size: 24px;
                        .caret {
                            @media (min-width: $screen-md-min) {
                                display: none;
                            }
                        }
                    }
                }
                .dropdown-menu {
                    list-style-type: none;
                    padding: 0;
                    position: static;
                    float: none;
                    width: auto;
                    margin-top: 0;
                    background-color: transparent;
                    border: 0;
                    box-shadow: none;
                    @media (min-width: $screen-md-min) {
                        display: block;
                    }
                }
            }
        }
    }
    .contact-megamenu {
        color: #ffffff;
        h3 {
            margin: 14px 0;
        }
        p {
            line-height: 1.6em;
        }
        a {
            padding: 0 !important;
            color: #ffffff;
        }
    }
}

.search-collapse {
    padding: 30px 30px 20px 30px;
    input {
        margin-bottom: 10px;
    }
}

.homeheader-container {
    margin-top: 90px;
}

.homeslider-container {
    z-index: 0;
    position: relative;
    text-align: left;
    height: 530px;
    background-color: $brand-primary;
    color: #ffffff;
    overflow: hidden;
    .homeslider-front {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        .container {
            height: 100%;
            .header-row {
                height: 100%;
                .header {
                    position: relative;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    @media (max-width: 768px) {
                        display: none;
                    }
                    h2 {
                        color: $brand-primary;
                        background-color: #ffffff;
                        margin: 0;
                        font-size: 24px;
                        line-height: 1.2em;
                        letter-spacing: 0.01em;
                        font-style: italic;
                        padding: 24px;
                    }
                    span {
                        float: right;
                        background-color: $brand-primary;
                        color: #ffffff;
                        padding: 10px;
                        font-size: 18px;
                        font-family: $headings-font-family;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.homepage-smallmenu-container {
    position: absolute;
    z-index: 20;
    bottom: 0;
    width: 100%;
    text-align: center;
    #homepage-smallmenu {
        padding: 0;
        margin-bottom: 0;
        list-style-type: none;
        li {
            padding: 12px 0;
            text-transform: uppercase;
            display: inline-block;
            background-color: #ffffff;
            color: $brand-primary;
            margin-right: -1px;
            -webkit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
            &:hover, &:active {
                background-color: darken(#ffffff, 10%);
            }
            &:nth-child(n+2) {
                margin-left: 2px;
            }
            &:last-of-type {
                margin-right: -2px;
            }
        }
        a {
            display: block;
            width: 100%;
            height: 100%;
            &:hover {
                text-decoration: none;
            }
        }
    }

}

.homepage-menu-container {
    z-index: 20;
    @media (max-width: $screen-md-max) {
        font-size: 14px;
    }
    #homepage-menu {
        color: #ffffff;
        list-style-type: none !important;
        padding: 0;
        margin-bottom: 0;
        a {
            color: #ffffff;
        }
        .main-item {
            position: relative;
            border-right: 1px solid #ffffff;
            height: 110px;
            cursor: pointer;
            .animate-block {
                background-color: $brand-primary;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: auto;
                .main-item-link {
                    padding: 0 20px;
                    display: inline-block;
                    height: 110px;
                    width: 100%;
                    font-size: 14px;
                    @include media('<1000px') {
                        font-size: 12px;
                    }
                    h3 {
                        border-bottom: 1px solid #ffffff;
                        margin-right: 30px;
                        padding-bottom: 10px;
                        text-transform: uppercase;
                        -webkit-transition: 0.2s ease-in-out;
                        -moz-transition: 0.2s ease-in-out;
                        -o-transition: 0.2s ease-in-out;
                        transition: 0.2s ease-in-out;
                        @include media('<1540px') {
                            font-size: 18px;
                        }
                        @include media('<1540px') {
                            font-size: 16px;
                        }
                        @include media('<1262px') {
                            font-size: 14px;
                        }
                        @include media('<1000px') {
                            font-size: 12px;
                        }
                        a {
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
                &:hover {
                    h3 {
                        margin-right: 0;
                    }
                }
            }

            &:last-of-type {
                border-right: 0;
            }

            @media (max-width: $screen-xs-max) {
                border-right: 0;
                border-bottom: 1px solid #ffffff;
            }

            .animate-block > .sub-menu {
                display: none;
                width: 100%;
                position: relative;
                background-color: $brand-primary;
                list-style-type: none !important;
                padding: 0 20px 10px;
                margin: 0;
                &.open {
                    left: -5000px;
                }
                li {
                    margin-bottom: 10px;

                    .sub-menu {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 5020px;
                        margin-left: 10px;
                        list-style: none;
                        padding: 0;
                    }
                }
            }
        }
        a {
            color: #ffffff;
        }
    }
}

.header-container {
    position: relative;
    text-align: left;
    margin-top: 90px;
    height: 170px;
    background-color: $brand-primary;
    color: #ffffff;
    overflow: hidden;
    -webkit-box-shadow: inset 0px 86px 110px -85px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0px 86px 110px -85px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0px 86px 110px -85px rgba(0, 0, 0, 0.5);
    &.has-background {
        height: 270px;
    }
    .header-front {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        .container {
            height: 100%;
            .header-row {
                height: 100%;
                .header {
                    position: relative;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    h1 {
                        color: #ffffff;
                        margin: 0;
                        font-size: 48px;
                        font-family: sans-serif !important;
                        @media (max-width: $screen-xs-max) {
                            font-size: 36px;
                        }
                    }
                }
            }
        }
    }
}

#breadcrumbs {
    margin-bottom: 0;
    color: $breadcrumb-color;
    a {
        color: $breadcrumb-color;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.page-header {
    padding: 0;
    border: 0;
    margin: 15px 0;
}

.home-button {
    @media (max-width: 670px) {
        display: none;
    }
}

.zkn-widget-type-2, .zkn-widget-type-4
{
    width: 100% !important;
}