.btn {
  display: inline-block;
  position: relative;
  font-weight: $nav-font-weight;
  font-family: $nav-font-family;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 0;
  border: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: uppercase;
  color: #ffffff;
  background-color: $brand-secondary;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  &:hover, &:active {
    background-color: darken($brand-secondary, 10%);
  }
}
.btn-team {
  width: 100%;
  background-color: $brand-primary;
  &:hover, &:active {
    background-color: darken($brand-primary, 10%);
  }
}