// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;

// Colors
$text-color:            #333333;
$hover-color:           #999999;
$brand-primary:         #f27c41;
$brand-secondary:       #f9ab84;
$brand-tertiary:        #FCEDE6;
$brand-quaternary:      #f27c41;
$brand-quole-primary:   #00958F;
$border-color:          #b4d0d0;
$secondary-border-color: #d8e0e0;
$breadcrumb-color:      #b2e8e6;

// Typography
$font-size:			      16px;
$font-family-sans-serif:  "proxima-nova", sans-serif;

$headings-font-family:    "futura-pt", sans-serif;
$headings-font-weight:    400;
$headings-line-height:    1em;

$nav-font-family:         "proxima-nova", sans-serif;
$nav-font-weight:         400;
$nav-line-height:         1em;
$navbar-default-color:    #ffffff;
$navbar-default-link-color: #ffffff;
$navbar-default-bg:       $brand-primary;
$navbar-default-border:   transparent;
$navbar-default-brand-color:  #ffffff;
$navbar-default-brand-hover-color:  #ffffff;
$navbar-default-link-hover-color:   #ffffff;
$navbar-default-link-hover-bg:      $brand-primary;
$navbar-default-link-active-color:  #ffffff;
$navbar-default-link-active-bg:      $brand-primary;

$navbar-padding-vertical:   0;
$navbar-padding-horizontal: 0;

$grid-float-breakpoint:     99999px;