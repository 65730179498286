.newsblock-row {
  margin-top: 30px;
  a {
    color: $text-color;
    .fa {
      color: $brand-primary;
    }
  }
}
.news_text {
  background-color: #ffffff;
  padding: 16px 13px 6px;
  position: relative;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  i {
    position: absolute;
    bottom: 18px;
    right: 12px;
  }
  &:hover {
    background-color: darken(#ffffff, 5%);
  }
}
.news_date {
  color: $hover-color;
  font-family: $headings-font-family;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 10px;
  i {
    position: relative;
    left: 0;
    top: 0;
    margin-right: 10px;
  }
}
.news-list-block {
  .news_date {
    margin-bottom: 0;
  }
}
.newsitem-content {
  padding: 20px;
}
.newsitem-row {
  background-color: #ffffff;
  display: table;
  width: 100%;
  h3 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    color: $brand-primary;
  }
  p {
    margin-bottom: 20px;
  }
  .news_date {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: $screen-xs-max) {
    display: inline-block;
  }
  .newsitem-block {
    vertical-align: top;
    float: none;
    display: table-cell;
    padding: 5px 30px;
    @media only screen and (max-width: $screen-xs-max) {
      float: inherit;
      display: inline-block;
      &.img-block {
        min-height: 300px;
      }
    }
  }
}
.entry-summary {
  i {
    margin-left: 10px;
    vertical-align: middle;
  }
}