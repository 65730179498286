.map-wrap {
  position: relative;
  overflow: hidden;
  height: 430px;
  margin-top: 20px;
  width: 100%;
  .overlay {
    width: 100%;
    min-height: 430px;
    position: absolute;
    top: 0;
    z-index: 10;
  }
  #gmap_canvas {
    height: 430px;
    width: 100%;
    img {
      max-width: none !important;
      background: none!important
    }
  }
}