.intro-container {
  background-color: $brand-tertiary;
  padding-top: 50px;
  padding-bottom: 50px;
  img {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
  }
}