.usps {
  padding: 20px 0;
  background: repeating-linear-gradient(
    135deg,
    $brand-tertiary,
    $brand-tertiary 5px,
    $secondary-border-color 5px,
    $secondary-border-color 6px
  );
  text-align: center;
  .usps-col {
    padding: 15px 0;
    text-align: center;
    div {
      display: inline;
      font-size: 16px;
      width: auto;
      text-align: left;
      padding: 12px 0 12px 50px;
    }
  }
}