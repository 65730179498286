.sidebar {
  padding-top: 20px;
  padding-bottom: 20px;
    float: left;
  h3 {
    margin-top: 10px;
  }
  .openinghours {
    line-height: 2em;
    margin-bottom: 1.8em;
  }
}
.sidebar-contact {
  display: inline-block;
  color: #ffffff;
  background-color: $brand-quole-primary;
  padding: 20px;
  h3 {
    margin-top: 0;
  }
  .btn {
    background-color: $brand-quaternary;
    border: 2px solid #ffffff;
    min-width: 100%;
    &:hover, &:active {
      background-color: darken($brand-quaternary, 10%);
    }
  }
}
ul.nav-pills.nav-stacked {
  margin-bottom: 22px;
  li {
    a {
      background-color: #dbd9d9;
      color: #ffffff;
      &:hover {
        background-color: $brand-primary;

      }
    }
  }
  li.current_page_item {
    a {
      background-color: $brand-primary;
    }

  }
}
