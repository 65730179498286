body {
  background-color: $brand-tertiary;
  font-size: 16px;
}

.sidebar-primary {
  .main {
    margin-top: -30px;
  }
}

article {
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 20px;
  h2.entry-title {
    margin-top: 0;
  }
}

// Not a fan of this, but it's the only solution at this time.
.tableizer-firstrow th {
  &:nth-child(1) {
    width: 10%;
  }

  &:nth-child(2) {
    width: 15%;
  }

  &:nth-child(3) {
    width: 65%;
  }

  &:nth-child(4) {
    width: 10%;
    text-align: right;
  }
}

.tableizer-table tbody tr td:nth-child(4) {
  text-align: right;
}

// Swiper fix
.swiper-container {
  position: relative;
  overflow: hidden;
}

.swiper-wrapper {
  position: relative;
  width: 10000%;
}

.swiper-wrapper .swiper-slide {
  position: relative;
  float: left;
}

.sticky-wrapper {
  z-index: 1;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}