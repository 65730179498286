.single-team {
  .teamimage-block {
    margin-top: -30px;
    height: 680px;
    margin-bottom: 20px;
    .quote {
      position: relative;
      top: 100%;
      -webkit-transform: translateY(-175%);
      -ms-transform: translateY(-175%);
      transform: translateY(-175%);
      h3 {
        color: $brand-primary;
        background-color: #ffffff;
        margin: 0;
        font-size: 32px;
        line-height: 1.2em;
        letter-spacing: 0.01em;
        font-style: italic;
        padding: 24px;
      }
      span {
        float: right;
        background-color: $brand-primary;
        color: #ffffff;
        padding: 10px;
        font-size: 18px;
        font-family: $headings-font-family;
        text-transform: uppercase;
      }
    }
  }
  .entry-content {
    padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 32px;
    h3 {
      font-family: $font-family-sans-serif;
      font-weight: 700;
      margin-bottom: 32px;
    }
    p {
      line-height: 1.6em;
      margin-bottom: 34px;
    }
  }
  .team-button-row {
    margin-bottom: 36px;
  }
  .news-list-row {
    margin-bottom: 10px;
    .news-list-block {
      margin-bottom: 20px;
    }
  }
}

.team-container {
  padding-top: 20px;
  padding-bottom: 40px;
  h3 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    color: $brand-primary;
    margin: 26px 0;
  }
  .team-member {
    text-align: center;
    padding-bottom: 20px;
    img {
      width: 150px;
      height: auto;
      border-radius: 75px;
      margin: auto;
    }
    h6 {
      color: $text-color;
      font-size: $font-size;
      font-family: $font-family-sans-serif;
      margin-top: 16px;
      margin-bottom: 6px;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.teamslider-container {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  background-color: #ffffff;
  .swiper-container {
    text-align: center;
    margin: 20px auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .container {
    position: relative;
    img {
      width: 150px;
      height: auto;
      border-radius: 75px;
      margin: auto;
    }
    h6 {
      color: $text-color;
      font-size: $font-size;
      font-family: $font-family-sans-serif;
      margin-top: 16px;
      margin-bottom: 10px;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .teamslider-next, .teamslider-prev {
    position: absolute;
    top: 65px;
    z-index: 10;
    cursor: pointer;
    color: $brand-primary;
  }
  .teamslider-prev {
    &:before {
      content: "\f053";
      font-family: FontAwesome;
    }
    left: 0px;

  }
  .teamslider-next {
    &:before {
      content: "\f054";
      font-family: FontAwesome;
    }
    right: 0px;
  }
}