footer.content-info {
  margin-top: 0;
  background-color: $brand-primary;
  color: #ffffff;
  line-height: 2em;
  padding-top: 40px;
  padding-bottom: 20px;
  img {
    max-width: 150px;
  }
  p {
    line-height: 1.8em;
  }
  h5 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
  }
  ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    font-size: 14px;
  }
  a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  a.sm {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    margin-right: 10px;
    margin-top: 5px;
    position: relative;
    i {
      color: $brand-primary;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  .colofon {
    margin-top: 20px;
    font-size: 12px;
    .copyright {
      text-align: left;
    }
    .credit {
      text-align: right;
      @media (max-width: $screen-xs-max) {
        text-align: left;
      }
    }
  }
}
