.main-content {
    background-color: #ffffff;
    padding: 10px 30px 30px;
    margin-bottom: 30px;
    h3 {
        font-family: $font-family-sans-serif;
        font-weight: 700;
        color: $brand-primary;
    }
    p {
        margin-bottom: 20px;
    }
}

.pages-list {
    padding: 0;
    list-style-type: none;
    margin-bottom: 40px;
    li {
        margin: 10px 0;
    }
    a {
        color: $text-color;
        &:hover {
            color: $brand-primary;
            text-decoration: none;
        }
    }
    .current_page_item {
        > a {
            font-weight: 700;
            color: $brand-primary;
        }
    }
}

.children {
    padding: 0;
    list-style-type: none;
    font-size: 14px;
    li {
        &:before {
            content: "\f105";
            font-family: FontAwesome;
            color: $brand-primary;
            margin-right: 1em;
        }
    }
}

.tarieven-collapse {
    .tarieven-tables {
        margin-top: 40px;
        margin-bottom: 40px;
        .panel-default > .panel-heading {
            background-color: white !important;
        }
    }
}