// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

input[type="text"], input[type="tel"], input[type="email"], input[type="search"], textarea {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid $border-color !important;
  border-radius: 0 !important;
  color: $text-color !important;
}
.contact {
  .wpcf7 {
    margin-top: 26px;
  }
  input[type="text"], input[type="tel"], input[type="email"], input[type="search"] {
    background-color: $brand-tertiary;
    margin-bottom: 6px;
    &.postcode {
      width:30%;
    }
    &.town {
      width: 68%;
      margin-left: 2%;
    }
  }
  textarea {
    background-color: $brand-tertiary;
    height: 318px;
  }
}
input[type="submit"] {
  width: 100%;
}
label {
  font-weight: 300;
  padding: 12px 0;
  @media (max-width: $screen-xs-max) {
    padding: 5px 0 0;
  }
}
